<template>
  <div class="text-left">
    <!-- <BaseHeader :title="'WriterLevels'"></BaseHeader> -->

    <BaseLoading
      style="min-height: 400px; height: 90vh"
      v-if="loading"
    ></BaseLoading>

    <section v-else>
      <div class=" mt-2 border-0 ">
        <div class="card p-3 d-flex align-items-center flex-row w-100 justify-content-between text-right d-flex" style>
          <v-text-field
          v-model="$store.state.search"
            dense
            outlined
            label="Filter Writer Levels"
            name="url"
            type="text"
            hide-details
          />
          <v-spacer></v-spacer>

          <div class="w-50 ">
            <button
            class="my-auto float-right btn-add ml-2"
            @click.stop="
              edit = false;
              writerLevelsForm.reset();
              $store.state.dialog = true;
              "
            >Add writer level</button>
          </div>
        </div>
        <!-- datatable -->
        <v-data-table
          :mobile-breakpoint="0"
          :headers="headers"
          :items="writerLevels"
          :search="$store.state.search"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <!-- <router-link
                class="white--text v-btn v-btn--contained v-btn--fab v-btn--round theme--light v-size--x-small success"
                :to="{ name: 'ViewSystemClient', params: { id: item.id || '' }}"
              >
                <v-icon small class="white--text">mdi-eye</v-icon>
              </router-link> -->
            <v-btn
              elevation="0"
              class="white--text"
              @click="
                editMode(item);
                edit = true;
                $store.state.dialog = true;
              "
              fab
              x-small
            >
              <v-icon color="primary" small>mdi-pencil</v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <!-- /datatable -->
      </div>

      <!-- clients modal -->
      <v-dialog
        v-model="$store.state.dialog"
        class="text-left"
        transition="dialog-top-transition"
        max-width="600"
      >
        <v-card :loading="writerLevelsForm.busy">
          <v-card-title class="text-h5">
            {{
              edit
                ? `Update ${writerLevelsForm.level || ""}`
                : "New Writer Level"
            }}
          </v-card-title>
          <v-card-text>
            <!--  form -->
            <form
              class="flex-fill text-left"
              ref="writerLevelsForm"
              @submit.prevent="createWriterLevel"
            >
              <div class>
                <div class="mb-3">
                  <span class="d-block text-muted"
                    >All fields are required</span
                  >
                </div>

                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      dense
                      outlined
                      label="Level"
                      name="level"
                      type="level"
                      v-model="writerLevelsForm.level"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="writerLevelsForm"
                            class="v-messages theme--light error--text"
                            field="level"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      dense
                      outlined
                      label="Orders to get here"
                      name="orders_to_get_here"
                      v-model="writerLevelsForm.orders_to_get_here"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="writerLevelsForm"
                            class="v-messages theme--light error--text"
                            field="orders_to_get_here"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      dense
                      outlined
                      label="Max Bids"
                      name="max_bids"
                      v-model="writerLevelsForm.max_bids"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="writerLevelsForm"
                            class="v-messages theme--light error--text"
                            field="max_bids"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      dense
                      outlined
                      label="Max Claims"
                      name="max_claims"
                      v-model="writerLevelsForm.max_claims"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="writerLevelsForm"
                            class="v-messages theme--light error--text"
                            field="max_claims"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      dense
                      outlined
                      label="Max Orders"
                      name="max_orders"
                      v-model="writerLevelsForm.max_orders"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="writerLevelsForm"
                            class="v-messages theme--light error--text"
                            field="max_orders"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      dense
                      outlined
                      label="Amount Per Page"
                      name="amount_per_page"
                      v-model="writerLevelsForm.amount_per_page"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="writerLevelsForm"
                            class="v-messages theme--light error--text"
                            field="amount_per_page"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>
                </v-row>

                <v-btn-toggle>
                  <v-btn
                    :disabled="writerLevelsForm.busy"
                    type="submit"
                    color="teal"
                    class="white--text"
                    >{{ edit ? "Update" : "Save" }}</v-btn
                  >
                  <v-btn
                    type="button"
                    @click="$store.state.dialog = !$store.state.dialog"
                    >Close</v-btn
                  >
                </v-btn-toggle>
              </div>
            </form>

            <!-- / form -->
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- clients modal -->
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "WriterLevels",
  computed: {
    ...mapState("writerlevels", [
      "writerLevelsForm",
      "loading",
      "writerLevels",
      "headers",
    ]),
    // ...mapState("clients", ["clients"]),
  },
  data() {
    return {
      countries: [],
      edit: false,
    };
  },

  methods: {
    ...mapActions("writerlevels", ["getWriterLevels"]),
    // ...mapActions("clients", ["getClients"]),
    createWriterLevel() {
      this.edit
        ? this.writerLevelsForm
            .put(`${this.$baseUrl}/Setups/WriterLevels`)
            .then((res) => {
              this.$store.state.dialog = false;
              this.$notify({
                title: "Success",
                text: `Writer level updated successifully.`,
                style: "success",
              });
              this.getWriterLevels();
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
              this.$notify({
                title: "Error",
                text: err.response.data
                  ? err.response.data.Message
                  : `Error while updating writer level`,
                style: "danger",
              });
            }) 
        : this.writerLevelsForm
            .post(`${this.$baseUrl}/Setups/WriterLevels`)
            .then((res) => {
              this.$store.state.dialog = false;
              this.$notify({
                title: "Success",
                text: `Writer level created successifully.`,
                style: "success",
              });
              this.getWriterLevels();
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
              this.$notify({
                title: "Error",
                text: err.response.data
                  ? err.response.data.Message
                  : `Error while creating writer level`,
                style: "danger",
              });
            });
    },

    editMode(item) {
      Object.assign(this.writerLevelsForm, { ...item });
    },
  },
  async mounted() {
    await this.getWriterLevels();
  },
};
</script>